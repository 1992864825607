var BaseRoutes;
(function (BaseRoutes) {
    BaseRoutes["Account.ContactDetails"] = "Account.ContactDetails";
    BaseRoutes["Account.Index"] = "Account.Index";
    BaseRoutes["Account.Language"] = "Account.Language";
    BaseRoutes["Account.PasswordChange"] = "Account.PasswordChange";
    BaseRoutes["Account.Reports"] = "Account.Reports";
    BaseRoutes["Bookings.Index"] = "Bookings.Index";
    BaseRoutes["Bookings.Order.Details"] = "Bookings.Order.Details";
    BaseRoutes["Bookings.Order.Index"] = "Bookings.Order.Index";
    BaseRoutes["Bookings.Orders"] = "Bookings.Orders";
    BaseRoutes["Bookings.Sale.Details"] = "Bookings.Sale.Details";
    BaseRoutes["Bookings.Sale.Index"] = "Bookings.Sale.Index";
    BaseRoutes["Bookings.Sales"] = "Bookings.Sales";
    BaseRoutes["Bookings.Calendar"] = "Bookings.Calendar";
    BaseRoutes["BuyGiftCard"] = "BuyGiftCard";
    BaseRoutes["BuyGiftCardBusiness"] = "BuyGiftCardBusiness";
    BaseRoutes["GiftCardOrderConfirmation"] = "GiftCardOrderConfirmation";
    BaseRoutes["Checkout"] = "Checkout";
    BaseRoutes["Error.NotFound"] = "Error.NotFound";
    BaseRoutes["Legal.ConditionsOfParticipation"] = "Legal.ConditionsOfParticipation";
    BaseRoutes["Legal.Fees"] = "Legal.Fees";
    BaseRoutes["Legal.GiftCardTerms"] = "Legal.GiftCardTerms";
    BaseRoutes["Legal.DiscountCodeTerms"] = "Legal.DiscountCodeTerms";
    BaseRoutes["Legal.LegalNotice"] = "Legal.LegalNotice";
    BaseRoutes["Legal.NomadyPact"] = "Legal.NomadyPact";
    BaseRoutes["Legal.PrivacyPolicy"] = "Legal.PrivacyPolicy";
    BaseRoutes["Legal.TermsOfService"] = "Legal.TermsOfService";
    BaseRoutes["Listing.Canonical"] = "Listing.Canonical";
    BaseRoutes["Listing.Create"] = "Listing.Create";
    BaseRoutes["Listing.Edit.Index"] = "Listing.Edit.Index";
    BaseRoutes["Listing.Edit.Payout"] = "Listing.Edit.Payout";
    BaseRoutes["Listing.Index"] = "Listing.Index";
    BaseRoutes["Listing.Single"] = "Listing.Single";
    BaseRoutes["Listing.Variant"] = "Listing.Variant";
    BaseRoutes["ListingFavoritesList"] = "ListingFavoritesList";
    BaseRoutes["ListingFavoritesLists"] = "ListingFavoritesLists";
    BaseRoutes["RentOfficeSpace"] = "RentOfficeSpace";
    BaseRoutes["MyCabin"] = "MyCabin";
    BaseRoutes["MyCabinCamper"] = "MyCabinCamper";
    BaseRoutes["MyCabinHosts"] = "MyCabinHosts";
    BaseRoutes["Fusion"] = "Fusion";
    BaseRoutes["HostFusion"] = "HostFusion";
    BaseRoutes["Profile.Index"] = "Profile.Index";
    BaseRoutes["Profile.Settings"] = "Profile.Settings";
    BaseRoutes["Profile.Single"] = "Profile.Single";
    BaseRoutes["Region.Single"] = "Region.Single";
    BaseRoutes["Inspiration.Single"] = "Inspiration.Single";
    BaseRoutes["AboutUs"] = "AboutUs";
    BaseRoutes["Camper"] = "Camper";
    BaseRoutes["Contact"] = "Contact";
    BaseRoutes["Host"] = "Host";
    BaseRoutes["Jobs"] = "Jobs";
    BaseRoutes["Landing"] = "Landing";
    BaseRoutes["Listings"] = "Listings";
    BaseRoutes["Login"] = "Login";
    BaseRoutes["Media"] = "Media";
    BaseRoutes["NomadyPact"] = "NomadyPact";
    BaseRoutes["Partner.Tcs"] = "Partner.Tcs";
    BaseRoutes["Partner.MountainHardwear"] = "Partner.MountainHardwear";
    BaseRoutes["Partners"] = "Partners";
    BaseRoutes["PasswordRecovery"] = "PasswordRecovery";
    BaseRoutes["PasswordReset"] = "PasswordReset";
    BaseRoutes["Scout"] = "Scout";
    BaseRoutes["Search"] = "Search";
    BaseRoutes["Signup"] = "Signup";
    BaseRoutes["VerifyEmail"] = "VerifyEmail";
    BaseRoutes["Vision"] = "Vision";
    BaseRoutes["PlateRedirect"] = "PlateRedirect";
    BaseRoutes["Regions"] = "Regions";
    BaseRoutes["Inspirations"] = "Inspirations";
})(BaseRoutes || (BaseRoutes = {}));
export default BaseRoutes;
